import { DashboardLayoutCustomer } from "../../components/dashboard-layout-customer";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box, Button, Select, Container, Table, Pagination, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import axios from "axios";
import swal from "sweetalert";
import { useEffect, useState } from "react";
import { GetNameChoose } from "../../funcUtils";

function HistoryBetAll() {
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);
    const [searched, setSearched] = useState("");
    const [isShow, setShow] = useState(false);
    const [ls, setLs] = useState(null);
    const [load, setLoad] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [endDate, setEndDate] = useState(new Date());
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const requestSearch = (searchedVal) => {
        setSearched(searchedVal);

        if (searchedVal !== "") {
            const filteredRows = data2.filter((row) => {
                const a =
                    row?.user?.username?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
                    searchedVal.toString().toLowerCase().includes(row?.user?.username?.toString().toLowerCase());
                const b = Number(row?.user?.iduser).toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                const c = row?.sanh?.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                return c || b || a;
            });
            setData(filteredRows);
            setPage(1);
        } else {
            setData(JSON.parse(localStorage.getItem("data")));
        }
    };
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/history/all?date=${endDate}`, {})
            .then((res) => {
                setData2(res.data.data);
                setData(res.data.data);
            })
            .catch((res) => setData(null));
    }, []);
    const [pages, setPage] = useState(1);

    const handleChange = (e, page) => {
        setPage(page);
    };

    const selectHistory = (date) => {
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/history/all?date=${date}`, {})
            .then((res) => {
                localStorage.setItem("historyapi", JSON.stringify(res.data.data));
                setData(res.data.data);
                setData2(res.data.data);
                setLoading(false);
            })
            .then((res) => {
                setLoad(true);
                setLoading(false);
            });
    };
    /*const [table, setTable] = useState("");
    const handleChangeTable = (e) => {
        setTable(e.target.value);
        console.log(e.target.value);
        if (table !== "") {
            const filteredRows = data2.filter((row) => {
                return row.sanh.includes(table);
            });
            setData(filteredRows);
        } else {
            setData(JSON.parse(localStorage.getItem("data")));
        }
    };

    const XSMN = [
        "Bạc Liêu",
        "Vũng Tàu",
        "Tiền Giang",
        "Kiên Giang",
        "Đà Lạt",
        "Bình Phước",
        "Bình Dương",
        "An Giang",
        "Bình Thuận",
        "Cà Mau",
        "Cần Thơ",
        "Hậu Giang",
        "Đồng Tháp",
        "Tây Ninh",
        "Vĩnh Long",
        "Trà Vinh",
        "Sóc Trăng",
        "Long An",
        "TP. HCM",
        "Đồng Nai",
        "Bến Tre"
    ];
    const XSMT = [
        "Đà Nẵng",
        "Thừa T. Huế",
        "Quảng Trị",
        "Phú Yên",
        "Quảng Bình",
        "Quảng Nam",
        "Quảng Ngãi",
        "Ninh Thuận",
        "Kon Tum",
        "Khánh Hòa",
        "Gia Lai",
        "Bình Định",
        "Đắk Lắk",
        "Đắk Nông"
    ];*/

    const [st, setSt] = useState(0);
    const handleChangeStatus = (e) => {
        if (ls?.status_bet === "Lose" || ls?.status_bet === "Pending") {
            if (e.target.value === "Win") {
                setSt(1);
            }
        } else {
            if (e.target.value === "Lose" || e.target.value === "Pending") {
                setSt(2);
            }
        }
        setLs((prevLs) => ({
            ...prevLs,
            status_bet: e.target.value
        }));
    };

    const handleSubmitLs = (e) => {
        e.preventDefault();
        const formData = {
            id: ls?._id,
            userId: ls?.user?._id,
            status_bet: e.target.status_bet.value,
            state: e.target.state.value,
            money: e.target.money.value,
            moneyuser: st == 1 ? e.target.moneythang.value : st == 2 ? Number(ls?.moneythang) * -1 : 0,
            moneythang: e.target.moneythang.value
        };
        axios
            .patch(`${process.env.REACT_APP_API_URL}/history`, formData)
            .then((res) => {
                setShow(false);
                swal("Cập nhật thành công", "", "success").then((value) => {
                    window.location.reload();
                });
            })
            .catch((err) => {
                setShow(false);
                swal("Thông báo", "Đã xảy ra lỗi, vui lòng thử lại!", "error");
            });
    };
    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayoutCustomer>
                    {
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                py: 1
                            }}>
                            {isLoading ? (
                                <div className="loading" style={{ maxWidth: "100%" }}>
                                    <div className="loader"></div>
                                </div>
                            ) : null}
                            <Container maxWidth={false}>
                                <div className="container_set">Lịch sử giao dịch</div>
                                <div className="form_set">
                                    <Box>
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", maxWidth: "600px", margin: "auto" }}>
                                            <TextField value={searched} onChange={(searchVal) => requestSearch(searchVal.target.value)} placeholder="Tìm kiếm" />
                                            <DatePicker
                                                maxDate={new Date()}
                                                selected={endDate}
                                                onChange={(date) => {
                                                    setEndDate(date);
                                                    selectHistory(date);
                                                }}
                                            />
                                            {/*<div>
                                                <span>Chọn trò chơi </span>
                                                <select onChange={handleChangeTable} value={table} id="table">
                                                    <option value="">Tất cả</option>
                                                    <option value="XSMB">XSMB</option>
                                                    <option value="XSMN">XSMN</option>
                                                    <option value="XSMT">XSMT</option>
                                                </select>
                                            </div>*/}
                                        </div>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ padding: "10px" }}>Số kỳ</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>User</TableCell>
                                                    {/*<TableCell sx={{padding:"10px"}}>ID</TableCell>*/}
                                                    <TableCell sx={{ padding: "10px" }}>Trò chơi</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Người chơi đặt</TableCell>
                                                    {/*<TableCell sx={{ padding: "10px" }}>Kết quả</TableCell>*/}
                                                    <TableCell sx={{ padding: "10px" }}>Số tiền cược</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Số tiền thắng</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Trạng thái</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Thời gian đặt</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Hủy/trả thưởng</TableCell>
                                                    {/*<TableCell sx={{ padding: "10px" }}>Sửa</TableCell>*/}
                                                </TableRow>
                                            </TableHead>
                                            {data != null ? (
                                                <TableBody>
                                                    {data?.slice((pages - 1) * 12, (pages - 1) * 12 + 12).map((item) => (
                                                        <>
                                                            <TableRow>
                                                                <TableCell
                                                                    sx={{
                                                                        fontWeight: "600",
                                                                        padding: "10px"
                                                                    }}>
                                                                    {item?.id_bet?.id_bet ? item?.id_bet?.id_bet : item?.id_bet}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{
                                                                        fontWeight: "600",
                                                                        padding: "10px"
                                                                    }}>
                                                                    {item?.user?.username}
                                                                </TableCell>
                                                                {/*<TableCell sx={{ fontWeight: "600", padding: "10px" }}>
																				{item?.user?.iduser}
																				</TableCell>*/}
                                                                <TableCell
                                                                    sx={{
                                                                        fontWeight: "600",
                                                                        padding: "10px"
                                                                    }}>
                                                                    {item?.sanh}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{
                                                                        fontWeight: "600",
                                                                        padding: "10px",
                                                                        maxWidth: "500px"
                                                                    }}>
                                                                    {GetNameChoose(item?.state, item?.type, item?.sanh)}
                                                                </TableCell>
                                                                {/*<TableCell
                                                                    sx={{
                                                                        fontWeight: "600",
                                                                        padding: "10px"
                                                                    }}>
                                                                    {item?.id_bet ? item?.id_bet.result : ""}
                                                                </TableCell>*/}
                                                                <TableCell
                                                                    sx={{
                                                                        fontWeight: "600",
                                                                        padding: "10px"
                                                                    }}>
                                                                    {item?.money.toLocaleString("vi-VN")}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{
                                                                        fontWeight: "600",
                                                                        padding: "10px"
                                                                    }}>
                                                                    {item?.moneythang.toLocaleString("vi-VN")}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{
                                                                        fontWeight: "600",
                                                                        padding: "10px"
                                                                    }}>
                                                                    {item?.status_bet === "Win" ? (
                                                                        <span className="badge success">Thắng</span>
                                                                    ) : item?.status_bet === "Lose" ? (
                                                                        <span className="badge error">Thua</span>
                                                                    ) : item?.status_bet === "Pending" ? (
                                                                        <span className="badge warning">Chờ xổ</span>
                                                                    ) : (
                                                                        <span className="badge cancel">Đã hủy</span>
                                                                    )}
                                                                </TableCell>

                                                                <TableCell
                                                                    sx={{
                                                                        fontWeight: "600",
                                                                        padding: "10px"
                                                                    }}>
                                                                    {formatDate(new Date(item?.createdAt))}
                                                                </TableCell>
                                                                <TableCell
                                                                    sx={{
                                                                        fontWeight: "600",
                                                                        padding: "10px"
                                                                    }}>
                                                                    {formatDate(new Date(item?.updatedAt))}
                                                                </TableCell>
                                                                {/*<TableCell sx={{ padding: "10px" }}>
                                                                    <Button
                                                                        onClick={() => {
                                                                            setShow(true);
                                                                            setLs(item);
                                                                        }}>
                                                                        Sửa
                                                                    </Button>
                                                                    </TableCell>*/}
                                                            </TableRow>
                                                        </>
                                                    ))}
                                                </TableBody>
                                            ) : (
                                                <div>Đang cập nhật</div>
                                            )}
                                        </Table>
                                    </Box>
                                </div>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        pt: 3
                                    }}>
                                    {data != null ? <Pagination color="primary" count={Math.floor(data.length / 12) + 1} size="small" onChange={handleChange} /> : null}
                                </Box>
                            </Container>
                        </Box>
                    }

                    {isShow === true ? (
                        <>
                            <div className="modal">
                                <div className="modaloverlay">
                                    <i className="ti-close closelogin"></i>
                                </div>
                                <div className="modalbody">
                                    <form onSubmit={handleSubmitLs}>
                                        <div className="modalinner">
                                            <div className="modalheader"> Sửa lịch sử </div>

                                            <div className="modalform">
                                                <div className="modalformgroup d-flex" style={{ padding: "9px" }}>
                                                    <div>Người chơi: </div>
                                                    <div>
                                                        <b>{ls?.user?.username}</b>
                                                    </div>
                                                </div>
                                                <div className="modalformgroup d-flex" style={{ padding: "9px" }}>
                                                    <div>Trò chơi: </div>
                                                    <div>
                                                        <b>{ls?.sanh}</b>
                                                    </div>
                                                </div>
                                                <div style={{ padding: "9px" }} className="modalformgroup d-flex">
                                                    <div>Lựa chọn: </div>
                                                    <TextField name="state" defaultValue={ls?.state} sx={{ width: "100%" }} type="text" />
                                                </div>
                                                <div style={{ padding: "9px" }} className="modalformgroup d-flex">
                                                    <div>Số tiền cược: </div>
                                                    <TextField name="money" defaultValue={ls?.money} sx={{ width: "100%" }} type="number" />
                                                </div>
                                                <div style={{ padding: "9px" }} className="modalformgroup d-flex">
                                                    <div>Số tiền thắng: </div>
                                                    <TextField name="moneythang" defaultValue={ls?.moneythang} sx={{ width: "100%" }} type="number" />
                                                </div>
                                                <div style={{ padding: "9px" }} className="modalformgroup d-flex">
                                                    <div>Trạng thái: </div>
                                                    <div>
                                                        <select name="status_bet" value={ls?.status_bet} onChange={handleChangeStatus}>
                                                            <option value="Win" selected={ls?.status_bet === "Win"} style={{ color: "#14B8A6" }}>
                                                                Thắng
                                                            </option>
                                                            <option value="Lose" selected={ls?.status_bet === "Lose"} style={{ color: "#D14343" }}>
                                                                Thua
                                                            </option>
                                                            <option value="Pending" selected={ls?.status_bet === "Pending"} style={{ color: "#FFB020" }}>
                                                                Chờ xổ
                                                            </option>
                                                            <option value="Cancelled" selected={ls?.status_bet === "Cancelled"} style={{ color: "#eee" }}>
                                                                Đã hủy
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="item_btn_form">
                                                <div className="modalformcontrols">
                                                    <Button type="submit">XÁC NHẬN</Button>
                                                </div>
                                                <div className="modalformcontrols">
                                                    <Button onClick={() => setShow(false)}>ĐÓNG</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </>
                    ) : null}
                </DashboardLayoutCustomer>
            </ThemeProvider>
        </>
    );
}
export default HistoryBetAll;
