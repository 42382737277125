import { Box, Button, Container, Input, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import swal from "sweetalert";
import axios from "axios";
import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import "./account.css";
import { theme } from "../../theme";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GetNameChoose } from "../../funcUtils";

function UserProfile() {
    const { id } = useParams();
    const [isShow, setShow] = useState(false);
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);
    const [load, setLoad] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [profile, setProfile] = useState(null);
    const [bank, setBank] = useState(null);
    const [history, setHistory] = useState(null);
    const [daily, setDaily] = useState(null);
    const [popup, setPopup] = useState(null);
    const [total, setTotal] = useState(null);
    const [dataTable, setDatatable] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(new Date());
    const [api, setAPI] = useState(null);
    const [isLoading, setLoading] = useState(true);
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const openPopup = (item) => {
        setTotal(item);
        setIsOpen2(true);
    };
    const closePopup = () => {
        setIsOpen2(false);
    };
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        if (id && load == false) {
            axios
                .post(`${process.env.REACT_APP_API_URL}/bank/getBankUser`, { id: id })
                .then((res) => {
                    setData(res.data.data);
                    setLoad(true);
                })
                .catch((res) => setData(null));
        }
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/user/${id}`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                }
            })
            .catch((res) => setProfile(null));
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/getCustomer`, { code: profile?.code ? profile?.code : "..." })
            .then((res) => {
                setDaily(res.data.data);
            })
            .catch((err) => setDaily(null));
        axios
            .get(`${process.env.REACT_APP_API_URL}/history/historyuser/${id}`, {})
            .then((res) => {
                setHistory(res.data.data);
                setLoading(false);
            })
            .catch((res) => setHistory(null));
    }, [load]);
    const handleSubmit = (e) => {
        e.preventDefault();
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/changepassword`, {
                id: id,
                password: e.target.password.value
            })
            .then((res) => {
                setLoad(false);
                swal("Cập nhật thành công", "", "success");
            })
            .catch((err) => {
                swal("Thông báo", "Đã xảy ra lỗi, vui lòng thử lại!", "error");
                setData(null);
            });
    };
    const handleSubmit2 = (e) => {
        e.preventDefault();
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/changepassword`, {
                id: id,
                paypwd: e.target.paypwd.value
            })
            .then((res) => {
                setLoad(false);
                swal("Cập nhật thành công", "", "success");
            })
            .catch((err) => {
                swal("Thông báo", "Đã xảy ra lỗi, vui lòng thử lại!", "error");
                setData(null);
            });
    };
    const handleSubmitBank = (e) => {
        e.preventDefault();
        const formData = {
            id: bank._id,
            name_bank: e.target.name_bank.value,
            stk: e.target.stk.value,
            fullname: e.target.fullname.value
        };
        axios
            .post(`${process.env.REACT_APP_API_URL}/bank/updateBank`, formData)
            .then((res) => {
                setShow(false);
                swal("Cập nhật thành công", "", "success");
                setLoad(false);
            })
            .catch((err) => {
                setShow(false);
                swal("Thông báo", "Đã xảy ra lỗi, vui lòng thử lại!", "error");
                setLoad(false);
            });
    };
    const [employee, setEmployee] = useState();
    useEffect(() => {
        if (load == false) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/auth/getEmployee`, {})
                .then((res) => {
                    localStorage.setItem("data1", JSON.stringify(res.data.data));
                    setEmployee(res.data.data);
                    setLoad(true);
                })
                .then((res) => setLoad(true));
        }
    }, [load]);

    useEffect(() => {
        if (load == true) {
            setLoading(true);
            axios
                .get(`${process.env.REACT_APP_API_URL}/statistic/getallcustomer?dateStart=${startDate}&endDate=${endDate}&id=${id}`, {})
                .then((res) => {
                    setDatatable(res.data.data);
                    setLoading(false);
                })
                .catch((err) => setLoading(false));
        }
    }, [endDate, startDate, load]);
    const thongkeAff = (username) => {
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/thongkeaff`, { username })
            .then((res) => {
                setPopup(res.data.data);
                setOpen(true);
            })
            .catch((err) => swal("Chưa có dữ liệu", "Người chơi này chưa tham gia trò chơi nào", "info"));
    };
    /*useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/casino/history`, {})
            .then((res) => {
                const betHistory = JSON.parse(res.data.data.result);
                const newdata = betHistory.filter((item) => item?.member === profile?.username);
                setAPI(newdata);
            })
            .catch((err) => console.log(err));
    }, [profile]);*/
    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayout>
                    {
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                py: 8
                            }}>
                            {isLoading ? (
                                <div className="loading" style={{ maxWidth: "100%" }}>
                                    <div className="loader"></div>
                                </div>
                            ) : null}
                            <Container maxWidth={false}>
                                <div style={{ fontSize: "25px", fontWeight: 700 }}>Chỉnh sửa thông tin</div>
                                <div className="item_accountprofile">
                                    <div className="account_profile">
                                        <div className="titleitem">Thông tin cơ bản</div>
                                        {profile != null ? (
                                            <>
                                                <div className="avb_">
                                                    <div className="detail_user">
                                                        <div className="username_">Tên đăng nhập</div>
                                                        <div className="username_">{profile?.username}</div>
                                                    </div>
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="detail_user">
                                                            <div className="username_">Mật khẩu</div>
                                                            <div className="username_">
                                                                <TextField name="password" />
                                                                <Button type="submit">Xác nhận</Button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <form onSubmit={handleSubmit2}>
                                                        <div className="detail_user">
                                                            <div className="username_">Mật khẩu rút tiền</div>
                                                            <div className="username_">
                                                                <TextField name="paypwd" defaultValue={profile?.paypwd} />
                                                                <Button type="submit">Xác nhận</Button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <div className="detail_user">
                                                        <div className="username_">Số dư</div>
                                                        <div className="username_">{Math.floor(profile?.money)?.toLocaleString("vi-VN")}</div>
                                                    </div>

                                                    <div className="detail_user">
                                                        <div className="username_">Hoa hồng chưa thanh toán</div>
                                                        <div className="username_">{Number(profile?.moneyaff)?.toLocaleString("vi-VN")}</div>
                                                    </div>
                                                    <form
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            const formData = {
                                                                id: id,
                                                                level: e.target.vip.value
                                                            };
                                                            axios
                                                                .post(`${process.env.REACT_APP_API_URL}/auth/update`, formData)
                                                                .then((res) => swal("Cập nhật thành công!", "", "success"))
                                                                .catch(() => swal("Cập nhật không thành công", "", "error"));
                                                        }}>
                                                        <div className="detail_user">
                                                            <div className="username_">Vip</div>
                                                            <div className="username_">
                                                                <select name="vip" id="vip">
                                                                    <option selected={profile?.level === 0} value="0">
                                                                        1
                                                                    </option>
                                                                    <option selected={profile?.level === 2} value="2">
                                                                        2
                                                                    </option>
                                                                    <option selected={profile?.level === 3} value="3">
                                                                        3
                                                                    </option>
                                                                    <option selected={profile?.level === 4} value="4">
                                                                        4
                                                                    </option>
                                                                    <option selected={profile?.level === 5} value="5">
                                                                        5
                                                                    </option>
                                                                    <option selected={profile?.level === 6} value="6">
                                                                        6
                                                                    </option>
                                                                    <option selected={profile?.level === 7} value="7">
                                                                        7
                                                                    </option>
                                                                    <option selected={profile?.level === 8} value="8">
                                                                        8
                                                                    </option>
                                                                    <option selected={profile?.level === 9} value="9">
                                                                        9
                                                                    </option>
                                                                    <option selected={profile?.level === 10} value="10">
                                                                        10
                                                                    </option>
                                                                </select>
                                                                <Button type="submit">Xác nhận</Button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <div className="detail_user">
                                                        <div className="username_">Tổng cược</div>
                                                        <div className="username_">{Number(profile?.totalbet)?.toLocaleString("vi-VN")}</div>
                                                    </div>
                                                    <div className="detail_user">
                                                        <div className="username_">Tổng cược API</div>
                                                        <div className="username_">{Number(profile?.totalbetapi)?.toLocaleString("vi-VN")}</div>
                                                    </div>
                                                    <div className="detail_user">
                                                        <div className="username_">Admin đã cộng</div>
                                                        <div className="username_">{Number(profile?.adminadd)?.toLocaleString("vi-VN")}</div>
                                                    </div>
                                                    <div className="detail_user">
                                                        <div className="username_">Admin trừ vòng cược</div>
                                                        <div className="username_">{Number(profile?.admindec)?.toLocaleString("vi-VN")}</div>
                                                    </div>
                                                    <div className="detail_user">
                                                        <div className="username_">Admin đã thưởng</div>
                                                        <div className="username_">{Number(profile?.adminthuong)?.toLocaleString("vi-VN")}</div>
                                                    </div>
                                                    <div className="detail_user">
                                                        <div className="username_">Tiền đã đặt cược</div>
                                                        <div className="username_">{Number(profile?.totalbet)?.toLocaleString("vi-VN")}</div>
                                                    </div>
                                                    <div className="detail_user">
                                                        <div className="username_">Tiền đã thắng</div>
                                                        <div className="username_">{Number(profile?.totalwin)?.toLocaleString("vi-VN")}</div>
                                                    </div>
                                                    <div className="detail_user">
                                                        <div className="username_">Ngày tạo</div>
                                                        <div className="username_">{formatDate(new Date(profile?.createdAt))}</div>
                                                    </div>
                                                    <div className="detail_user">
                                                        <div className="username_">Người giới thiệu</div>
                                                        <div className="username_">
                                                            {employee && employee.find((x) => x.code === profile?.aff)?.username
                                                                ? employee.find((x) => x.code === profile?.aff)?.username
                                                                : "không nhập mã"}
                                                        </div>
                                                    </div>
                                                    <form
                                                        onSubmit={(e) => {
                                                            e.preventDefault();
                                                            const formData = {
                                                                id: id,
                                                                sdt: e.target.sdt.value,
                                                                name: e.target.name.value,
                                                                aff: e.target.aff.value
                                                            };
                                                            axios
                                                                .post(`${process.env.REACT_APP_API_URL}/auth/updateuser/${id}`, formData)
                                                                .then((res) => swal("Cập nhật thành công!", "", "success"))
                                                                .catch(() => swal("Cập nhật không thành công", "", "error"));
                                                        }}>
                                                        <div className="detail_user">
                                                            <div className="username_">Mã đại lý</div>
                                                            <div className="username_">
                                                                <input defaultValue={profile?.aff ? profile?.aff : "admin"} name="aff" />
                                                                <Button type="submit">Xác nhận</Button>
                                                            </div>
                                                        </div>
                                                        <div className="detail_user">
                                                            <div className="username_">SĐT</div>
                                                            <div className="username_">
                                                                <input defaultValue={profile?.sdt ? "0" + profile?.sdt : ""} name="sdt" />
                                                                <Button type="submit">Xác nhận</Button>
                                                            </div>
                                                        </div>
                                                        <div className="detail_user">
                                                            <div className="username_">Họ tên</div>
                                                            <div className="username_">
                                                                <input defaultValue={profile?.name ? profile?.name : ""} name="name" />
                                                                <Button type="submit">Xác nhận</Button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </>
                                        ) : null}
                                    </div>
                                    <div className="edit_account_bankl">
                                        <form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                const formData = {
                                                    id: id,
                                                    hoahong: e.target.hoahong.value,
                                                    hoahonglive: e.target.hoahonglive.value,
                                                    hoahongslots: e.target.hoahongslots.value
                                                };
                                                axios
                                                    .post(`${process.env.REACT_APP_API_URL}/auth/update`, formData)
                                                    .then((res) => swal("Cập nhật hoa hồng thành công!", "", "success"))
                                                    .catch(() => swal("Cập nhật không thành công", "", "error"));
                                            }}>
                                            <div className="detail_user" style={{ marginBottom: "20px", display: "block" }}>
                                                <div className="titleitem">Tỉ lệ hoa hồng đại lý</div>
                                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                                    <div className="username_">Hoa hồng xổ số</div>
                                                    <div className="username_">
                                                        <input name="hoahong" defaultValue={profile?.hoahong} style={{ width: "50px" }} /> %
                                                    </div>
                                                </div>
                                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                                    <div className="username_">Hoa hồng live casino</div>
                                                    <div className="username_">
                                                        <input name="hoahonglive" defaultValue={profile?.hoahonglive} style={{ width: "50px" }} /> %
                                                    </div>
                                                </div>
                                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                                    <div className="username_">Hoa hồng nổ hũ & bắn cá</div>
                                                    <div className="username_">
                                                        <input name="hoahongslots" defaultValue={profile?.hoahongslots} style={{ width: "50px" }} /> %
                                                    </div>
                                                </div>
                                                <div style={{ width: "100%" }}>
                                                    <Button type="submit">Xác nhận</Button>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="titleitem">Thông tin ngân hàng</div>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ padding: "10px" }}>Tên Ngân Hàng</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>STK</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Người Nhận</TableCell>
                                                    <TableCell sx={{ padding: "10px" }}>Hành động</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data != null ? (
                                                    <>
                                                        {data.map((item) => (
                                                            <TableRow>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.name_bank}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}> {item?.stk}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.fullname}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                    <Button
                                                                        onClick={() => {
                                                                            axios.delete(`${process.env.REACT_APP_API_URL}/bank/delete/${item?._id}`).then((res) => {
                                                                                setLoad(false);
                                                                            });
                                                                        }}>
                                                                        Xóa
                                                                    </Button>
                                                                    <Button
                                                                        onClick={() => {
                                                                            axios
                                                                                .get(`${process.env.REACT_APP_API_URL}/bank/user/${item?._id}`, {})
                                                                                .then((res) => {
                                                                                    setBank(res.data.data);
                                                                                    setShow(true);
                                                                                })
                                                                                .catch((res) => {
                                                                                    swal("Lấy thông tin không thành công", "", "error");
                                                                                    setLoad(false);
                                                                                });
                                                                        }}>
                                                                        Sửa
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <div
                                                        style={{
                                                            fontSize: "16px",
                                                            textAlign: "center",
                                                            padding: "10px"
                                                        }}>
                                                        Chưa có
                                                    </div>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    <div style={{ clear: "both" }}></div>
                                    <div className="profile-baocaodaily">
                                        <div style={{ maxHeight: "300px", overflowY: "auto", overflowX: "hidden", clear: "both", marginTop: "30px" }}>
                                            <div className="titleitem">Báo cáo đại lý</div>
                                            <Table sx={{ width: 600, minWidth: 600 }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Tài khoản</TableCell>
                                                        <TableCell>Số tiền cược</TableCell>
                                                        <TableCell>Hoa hồng</TableCell>
                                                        <TableCell>Nhật ký</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {daily != null ? (
                                                        daily.map((item) => (
                                                            <>
                                                                {item?.username != profile?.username && (
                                                                    <TableRow>
                                                                        <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                            <a>{item?.username}</a>
                                                                            <br />
                                                                            ID: {item?.iduser}
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{(item?.totalbet + item?.totalbetapi)?.toLocaleString("vi-VN")}</TableCell>
                                                                        <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                            {item?.moneyforaff?.toLocaleString("vi-VN")}
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                            <span className="badge blue" style={{ width: "13px" }} onClick={() => thongkeAff(item?.username)}>
                                                                                Kiểm tra
                                                                            </span>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )}
                                                            </>
                                                        ))
                                                    ) : (
                                                        <div
                                                            style={{
                                                                fontSize: "16px",
                                                                textAlign: "center",
                                                                padding: "10px"
                                                            }}>
                                                            Chưa có dữ liệu
                                                        </div>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </div>
                                    <div className="profile-thongke">
                                        <div className="titleitem">Thống kê</div>
                                        <div className="col-1-1">
                                            Từ ngày
                                            <DatePicker
                                                maxDate={new Date()}
                                                selected={startDate}
                                                onChange={(date) => {
                                                    setStartDate(date);
                                                    setLoad(true);
                                                }}
                                            />
                                        </div>
                                        <div className="col-1-1">
                                            Đến ngày
                                            <DatePicker
                                                maxDate={new Date()}
                                                selected={endDate}
                                                onChange={(date) => {
                                                    setEndDate(date);
                                                    setLoad(true);
                                                }}
                                            />
                                        </div>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}></TableCell>
                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>Nạp</TableCell>
                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>Rút</TableCell>
                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>Cược</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {dataTable != null ? (
                                                    <>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: "500", padding: "10px" }}>Thành công/thắng</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{dataTable?.tongnaps?.toLocaleString("vi-VN")}</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{dataTable?.tongruts?.toLocaleString("vi-VN")}</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{dataTable?.tongthang?.toLocaleString("vi-VN")}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: "500", padding: "10px" }}>Thất bại/thua</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{dataTable?.tongnapd?.toLocaleString("vi-VN")}</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{dataTable?.tongrutd?.toLocaleString("vi-VN")}</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{dataTable?.tongthua?.toLocaleString("vi-VN")}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: "500", padding: "10px" }}>Đang chờ</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{dataTable?.tongnapp?.toLocaleString("vi-VN")}</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{dataTable?.tongrutp?.toLocaleString("vi-VN")}</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{dataTable?.tongcho?.toLocaleString("vi-VN")}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: "500", padding: "10px" }}>Hủy</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>0</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>0</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{dataTable?.tonghuy?.toLocaleString("vi-VN")}</TableCell>
                                                        </TableRow>
                                                    </>
                                                ) : (
                                                    <div
                                                        style={{
                                                            fontSize: "16px",
                                                            textAlign: "center",
                                                            padding: "10px"
                                                        }}>
                                                        Chưa có
                                                    </div>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                                <div style={{ maxHeight: "500px", overflowY: "auto", overflowX: "hidden", marginTop: "30px" }}>
                                    <Table sx={{ width: "100%" }} className="table-admin">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ padding: "10px" }}>STT</TableCell>
                                                <TableCell sx={{ padding: "10px" }}>Phiên</TableCell>
                                                <TableCell sx={{ padding: "10px" }}>Loại giao dịch</TableCell>
                                                <TableCell sx={{ padding: "10px" }}>Chi tiết</TableCell>
                                                <TableCell sx={{ padding: "10px" }}>Kết quả</TableCell>
                                                <TableCell sx={{ padding: "10px" }}>Trạng thái</TableCell>
                                                <TableCell sx={{ padding: "10px" }}>Số tiền</TableCell>
                                                <TableCell sx={{ padding: "10px" }}>Số tiền thắng</TableCell>
                                                <TableCell sx={{ padding: "10px" }}>Khuyến mãi</TableCell>
                                                <TableCell sx={{ padding: "10px" }}>Số dư trước</TableCell>
                                                <TableCell sx={{ padding: "10px" }}>Số dư sau</TableCell>
                                                <TableCell sx={{ padding: "10px" }}>Thời gian</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {history != null ? (
                                                <>
                                                    {history.map((item, index) => (
                                                        <TableRow>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{index + 1}</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                {item?.id_bet?.id_bet ? item?.id_bet?.id_bet : item?.id_bet}
                                                            </TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}> {item?.sanh}</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px", maxWidth: "300px", wordBreak: "break-word" }}>
                                                                {item?.id_bet?.result ? (
                                                                    <>
                                                                        {item?.sanh == "Greedy" ? (
                                                                            <div>
                                                                                {Object.entries(item?.detail).map(
                                                                                    ([key, value]) =>
                                                                                        value > 0 && (
                                                                                            <div key={key} style={{ width: "130px", float: "left" }}>
                                                                                                <img
                                                                                                    alt=""
                                                                                                    src={require(`../game/Greedy/images/food_${Number(key.slice(-1)) + 1}.png`)}
                                                                                                    style={{ height: "25px", marginRight: "5px", position: "relative", top: "5px" }}
                                                                                                />
                                                                                                : {(value * 1000).toLocaleString("vi-VN")}
                                                                                            </div>
                                                                                        )
                                                                                )}
                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                {item?.sanh == "CatDog" ? (
                                                                                    <>
                                                                                        {Object.entries(item?.detail).map(
                                                                                            ([key, value]) =>
                                                                                                value > 0 && (
                                                                                                    <div key={key} style={{ width: "130px", float: "left" }}>
                                                                                                        {GetNameChoose(key.slice(-1), item?.type, item?.sanh)
                                                                                                            ? GetNameChoose(key.slice(-1), item?.type, item?.sanh)
                                                                                                            : "Chọn " + key.slice(-1)}
                                                                                                        : {(value * 1000).toLocaleString("vi-VN")}
                                                                                                    </div>
                                                                                                )
                                                                                        )}
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {item?.id_bet ? (
                                                                                            <div>
                                                                                                {GetNameChoose(item?.state, item?.type, item?.sanh)
                                                                                                    ? GetNameChoose(item?.state, item?.type, item?.sanh)
                                                                                                    : "Chọn " + item?.state}
                                                                                            </div>
                                                                                        ) : (
                                                                                            item?.detail
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>{item?.detail}</>
                                                                )}
                                                            </TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                {item?.id_bet?.result && (
                                                                    <>
                                                                        {item?.sanh == "Greedy" && (
                                                                            <img
                                                                                alt=""
                                                                                src={require(`../game/Greedy/images/food_${item?.id_bet?.result}.png`)}
                                                                                style={{ height: "25px" }}
                                                                            />
                                                                        )}
                                                                        {item?.sanh == "CatDog" && (
                                                                            <>{GetNameChoose(item?.id_bet?.result, item?.type, item?.sanh).replace("Chọn", "")}</>
                                                                        )}
                                                                        {item?.sanh.indexOf("Tài xỉu") != -1 && (
                                                                            <div
                                                                                className="history_xucsac"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    transform: "scale(2)",
                                                                                    margin: "0 0 0 -30%"
                                                                                }}>
                                                                                {item?.id_bet?.result?.split(" ").map((item) => (
                                                                                    <div className={`n${item}`}></div>
                                                                                ))}
                                                                            </div>
                                                                        )}
                                                                        {(item?.sanh.indexOf("Xổ số") != -1 ||
                                                                            item?.sanh === "XSMB" ||
                                                                            item?.sanh === "XSMT" ||
                                                                            item?.sanh === "XSMN") && (
                                                                            <>
                                                                                {item?.id_bet?.dacbiet && (
                                                                                    <span className="badge" style={{ fontSize: "12px" }} onClick={() => openPopup(item?.id_bet)}>
                                                                                        Kiểm tra
                                                                                    </span>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        {item?.sanh.indexOf("Xóc đĩa") != -1 && (
                                                                            <div
                                                                                className="history_xucsac"
                                                                                style={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                    transform: "scale(0.9)"
                                                                                }}>
                                                                                {item?.id_bet?.result?.split(" ").map((item) => (
                                                                                    <div className={`a${item}`}></div>
                                                                                ))}
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                {item?.status_payment
                                                                    ? item?.status_payment
                                                                    : item?.status_bet
                                                                    ? item?.status_bet
                                                                    : item?.status
                                                                    ? item?.status
                                                                    : null}
                                                            </TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.money?.toLocaleString("vi-VN")}</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.moneythang?.toLocaleString("vi-VN")}</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.km?.toLocaleString("vi-VN")}</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.before?.toLocaleString("vi-VN")}</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.after?.toLocaleString("vi-VN")}</TableCell>
                                                            <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{formatDate(new Date(item?.createdAt))}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </>
                                            ) : (
                                                <div
                                                    style={{
                                                        fontSize: "16px",
                                                        textAlign: "center",
                                                        padding: "10px"
                                                    }}>
                                                    Đang cập nhật dữ liệu
                                                </div>
                                            )}
                                        </TableBody>
                                    </Table>
                                </div>
                                <br />
                                <br />
                                {/*api != null ? (
                                    <>
                                        <div style={{ fontSize: "16px", float: "left" }}>
                                            Tổng cược: <b>{api.reduce((total, item) => total + item?.bet, 0)?.toLocaleString("vi-VN")}</b>
                                        </div>
                                        <div style={{ fontSize: "16px", float: "left", marginLeft: "20px" }}>
                                            Tổng thắng: <b>{api.reduce((total, item) => total + item?.payout, 0)?.toLocaleString("vi-VN")}</b>
                                        </div>
                                        <div style={{ maxHeight: "500px", overflowY: "auto", overflowX: "hidden", clear: "both", marginTop: "20px" }}>
                                            <Table sx={{ width: "100%" }} className="table-admin">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={{ padding: "10px" }}>ID gd</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Tài khoản</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Số tiền</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Tiền thắng</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Nhà cung cấp</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Loại trò chơi</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Trạng thái</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Thời gian</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {api.map((item, index) => (
                                                        <>
                                                            <TableRow>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.id}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.member}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.bet?.toLocaleString("vi-VN")}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.payout?.toLocaleString("vi-VN")}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.site}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.product}</TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                    {item?.status == 1 ? "Hoàn thành" : item?.status == 0 ? "Chưa hoàn thành" : "Lỗi"}
                                                                </TableCell>
                                                                <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{formatDate(new Date(item?.match_time))}</TableCell>
                                                            </TableRow>
                                                        </>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </>
                                ) : null*/}
                            </Container>
                        </Box>
                    }
                    {isShow === true ? (
                        <>
                            <div className="modal">
                                <div className="modaloverlay">
                                    <i className="ti-close closelogin"></i>
                                </div>
                                <div className="modalbody">
                                    {bank != null ? (
                                        <>
                                            <form onSubmit={handleSubmitBank}>
                                                <div className="modalinner">
                                                    <div className="modalheader"> Sửa thông tin </div>

                                                    <div className="modalform">
                                                        <div className="modalformgroup" style={{ padding: "9px" }}>
                                                            <TextField
                                                                name="name_bank"
                                                                defaultValue={bank.name_bank}
                                                                sx={{ width: "100%" }}
                                                                type="text"
                                                                placeholder="Tên ngân hàng"
                                                            />
                                                        </div>
                                                        <div style={{ padding: "9px" }} className="modalformgroup">
                                                            <TextField name="stk" defaultValue={bank.stk} sx={{ width: "100%" }} type="number" placeholder="STK" />
                                                        </div>
                                                        <div style={{ padding: "9px" }} className="modalformgroup">
                                                            <TextField
                                                                name="fullname"
                                                                defaultValue={bank.fullname}
                                                                sx={{ width: "100%" }}
                                                                type="text"
                                                                placeholder="Tên tài khoản"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="item_btn_form">
                                                        <div className="modalformcontrols">
                                                            <Button type="submit">XÁC NHẬN</Button>
                                                        </div>
                                                        <div className="modalformcontrols">
                                                            <Button onClick={() => setShow(false)}>ĐÓNG</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </>
                                    ) : (
                                        <div>Đang chờ dữ liệu</div>
                                    )}
                                </div>
                            </div>
                        </>
                    ) : null}
                    {isOpen === true ? (
                        <div className="popup-backdrop">
                            <div className="popup-main">
                                <div className="popup-header">
                                    Lịch sử của <b>{popup[0]?.username}</b>
                                </div>
                                <div className="popup-content content-history" style={{ padding: "0" }}>
                                    {popup &&
                                        popup.map((item) => (
                                            <>
                                                <div className="item_inner">
                                                    <div className="item_history">
                                                        <div className="title_item_history">
                                                            <span className="sanh">{item?.game}</span>
                                                        </div>
                                                        <div className="id_history_sanh">Phiên cược: {item?.id_bet?.id_bet ? item?.id_bet?.id_bet : item?.id_bet}</div>
                                                        <div className="id_history_sanh">Hoa hồng nhận được: {Number(item?.hoahong)?.toLocaleString("vi-VN")}</div>
                                                    </div>
                                                    <div className="money_history">
                                                        <span className="money">{Number(item?.money)?.toLocaleString("vi-VN")}</span>
                                                        <div className="time_choose">{formatDate(new Date(item?.createdAt))}</div>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                </div>
                                <button
                                    onClick={() => setOpen(false)}
                                    className="popup-close"
                                    style={{
                                        background: "#00b977",
                                        boxShadow: "none",
                                        textShadow: "none",
                                        fontSize: "18px"
                                    }}>
                                    Đóng
                                </button>
                            </div>
                        </div>
                    ) : null}
                    {isOpen2 && total ? (
                        <div className="popup-backdrop" style={{ maxWidth: "100%" }}>
                            <div className="popup-main" style={{ maxWidth: "500px", margin: "0 auto" }}>
                                <div className="popup-header" style={{ fontSize: "18px" }}>
                                    Phiên {total?.id_bet}
                                </div>
                                <div className="popup-content" style={{ padding: "0" }}>
                                    <table id="table-xsmb" className="table-result table table-bordered table-striped table-xsmb">
                                        <tbody>
                                            <tr>
                                                <th style={{ width: "10%" }}>ĐB</th>
                                                <td>
                                                    <span id="mb_prize_0" className="special-prize div-horizontal">
                                                        {total?.dacbiet}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>1</th>
                                                <td>
                                                    <span id="mb_prize_1" className="prize1 div-horizontal">
                                                        {total?.nhat}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>2</th>
                                                <td>
                                                    <span id="mb_prize_2" className="prize2 div-horizontal">
                                                        {total?.hai.split(" ")[0]}
                                                    </span>
                                                    <span id="mb_prize_3" className="prize2 div-horizontal">
                                                        {total?.hai.split(" ")[1]}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>3</th>
                                                <td>
                                                    <span id="mb_prize_4" className="prize3 div-horizontal">
                                                        {total?.ba.split(" ")[0]}
                                                    </span>
                                                    <span id="mb_prize_5" className="prize3 div-horizontal">
                                                        {total?.ba.split(" ")[1]}
                                                    </span>
                                                    <span id="mb_prize_6" className="prize3 div-horizontal">
                                                        {total?.ba.split(" ")[2]}
                                                    </span>
                                                    <span id="mb_prize_7" className="prize3 div-horizontal">
                                                        {total?.ba.split(" ")[3]}
                                                    </span>
                                                    <span id="mb_prize_8" className="prize3 div-horizontal">
                                                        {total?.ba.split(" ")[4]}
                                                    </span>
                                                    <span id="mb_prize_9" className="prize3 div-horizontal">
                                                        {total?.ba.split(" ")[5]}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>4</th>
                                                <td>
                                                    <span id="mb_prize_10" className="prize4 div-horizontal">
                                                        {total?.tu.split(" ")[0]}
                                                    </span>
                                                    <span id="mb_prize_11" className="prize4 div-horizontal">
                                                        {total?.tu.split(" ")[1]}
                                                    </span>
                                                    <span id="mb_prize_12" className="prize4 div-horizontal">
                                                        {total?.tu.split(" ")[2]}
                                                    </span>
                                                    <span id="mb_prize_13" className="prize4 div-horizontal">
                                                        {total?.tu.split(" ")[3]}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>5</th>
                                                <td>
                                                    <span id="mb_prize_14" className="prize5 div-horizontal">
                                                        {total?.nam.split(" ")[0]}
                                                    </span>
                                                    <span id="mb_prize_15" className="prize5 div-horizontal">
                                                        {total?.nam.split(" ")[1]}
                                                    </span>
                                                    <span id="mb_prize_16" className="prize5 div-horizontal">
                                                        {total?.nam.split(" ")[2]}
                                                    </span>
                                                    <span id="mb_prize_17" className="prize5 div-horizontal">
                                                        {total?.nam.split(" ")[3]}
                                                    </span>
                                                    <span id="mb_prize_18" className="prize5 div-horizontal">
                                                        {total?.nam.split(" ")[4]}
                                                    </span>
                                                    <span id="mb_prize_19" className="prize5 div-horizontal">
                                                        {total?.nam.split(" ")[5]}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>6</th>
                                                <td>
                                                    <span id="mb_prize_20" className="prize6 div-horizontal">
                                                        {total?.sau.split(" ")[0]}
                                                    </span>
                                                    <span id="mb_prize_21" className="prize6 div-horizontal">
                                                        {total?.sau.split(" ")[1]}
                                                    </span>
                                                    <span id="mb_prize_22" className="prize6 div-horizontal">
                                                        {total?.sau.split(" ")[2]}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>7</th>
                                                <td>
                                                    <span id="mb_prize_23" className="prize7 div-horizontal">
                                                        {total?.bay.split(" ")[0]}
                                                    </span>
                                                    <span id="mb_prize_24" className="prize7 div-horizontal">
                                                        {total?.bay.split(" ")[1]}
                                                    </span>
                                                    <span id="mb_prize_25" className="prize7 div-horizontal">
                                                        {total?.bay.split(" ")[2]}
                                                    </span>
                                                    <span id="mb_prize_26" className="prize7 div-horizontal">
                                                        {total?.bay.split(" ")[3]}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <button
                                    onClick={closePopup}
                                    className="popup-close"
                                    style={{
                                        background: "#00b977",
                                        boxShadow: "none",
                                        textShadow: "none",
                                        fontSize: "18px"
                                    }}>
                                    Đóng
                                </button>
                            </div>
                        </div>
                    ) : null}
                </DashboardLayout>
            </ThemeProvider>
        </>
    );
}

export default UserProfile;
