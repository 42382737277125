import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";
import "./users.css";
import axios from "axios";

import { Box, Button, Container, Input, Table, Pagination, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
//import swal from "sweetalert";

function UsersByEmployee() {
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [users, setUser] = useState(null);
    const [load, setLoad] = useState(false);
    const [data, setData] = useState(null);
    const [data2, setData2] = useState(null);
    const [searched, setSearched] = useState("");
    const navigate = useNavigate();
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const { id } = useParams();
    useEffect(() => {
        if (load == false) {
            axios
                .post(`${process.env.REACT_APP_API_URL}/auth/getCustomerEmployee`, { code: id })
                .then((res) => {
                    setData2(res.data.data);
                    setData(res.data.data);
                    setUser(res.data.data);
                    setLoad(true);
                })
                .then((res) => setLoad(true));
        }
    }, [load]);
    const requestSearch = (searchedVal) => {
        setSearched(searchedVal);

        if (searchedVal !== "") {
            const filteredRows = data2.filter((row) => {
                const a = row?.username.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                const b = Number(row.iduser).toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                return a || b;
            });
            setUser(filteredRows);
            setPage(1);
        } else {
            setUser(data2);
        }
    };
    const [pages, setPage] = useState(1);

    const handleChange = (e, page) => {
        setPage(page);
    };
    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayout>
                    {
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                py: 1
                            }}>
                            <Container maxWidth={false}>
                                <div className="container_set">Danh sách user</div>
                                {users ? (
                                    <>
                                        <div className="form_set">
                                            <Box>
                                                <TextField value={searched} onChange={(searchVal) => requestSearch(searchVal.target.value)} placeholder="Tìm kiếm" />
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ padding: "10px" }}>ID</TableCell>
                                                            <TableCell sx={{ padding: "10px" }}>Username</TableCell>
                                                            <TableCell sx={{ padding: "10px" }}>Số dư</TableCell>
                                                            <TableCell sx={{ padding: "10px" }}>Tổng cược</TableCell>
                                                            <TableCell sx={{ padding: "10px" }}>Cược thành công</TableCell>
                                                            <TableCell sx={{ padding: "10px" }}>Cược chưa trả</TableCell>
                                                            <TableCell sx={{ padding: "10px" }}>Cược API</TableCell>
                                                            <TableCell sx={{ padding: "10px" }}>IP cuối</TableCell>
                                                            <TableCell sx={{ padding: "10px" }}>Tổng nạp</TableCell>
                                                            <TableCell sx={{ padding: "10px" }}>Khuyến mãi</TableCell>
                                                            <TableCell sx={{ padding: "10px" }}>Tổng rút</TableCell>
                                                            {/* <TableCell sx={{padding:"10px"}}>Rút/ Nạp Tiền</TableCell>
														<TableCell sx={{padding:"10px"}}>Thưởng</TableCell>
														<TableCell sx={{padding:"10px"}}>Xem thông tin</TableCell> */}
                                                        </TableRow>
                                                    </TableHead>
                                                    {users != null ? (
                                                        <TableBody>
                                                            {users?.slice((pages - 1) * 12, (pages - 1) * 12 + 12).map((item) => (
                                                                <>
                                                                    <TableRow>
                                                                        <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.iduser}</TableCell>
                                                                        <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.username}</TableCell>
                                                                        <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.money.toLocaleString("vi-VN")} </TableCell>
                                                                        <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.totalbet.toLocaleString("vi-VN")}</TableCell>
                                                                        <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.success.toLocaleString("vi-VN")}</TableCell>
                                                                        <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                            {item?.processing.toLocaleString("vi-VN")}
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
                                                                            {item?.totalbetapi.toLocaleString("vi-VN")}
                                                                        </TableCell>
                                                                        <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.ip}</TableCell>
                                                                        <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.tongnap.toLocaleString("vi-VN")}</TableCell>
                                                                        <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.khuyenmai.toLocaleString("vi-VN")}</TableCell>
                                                                        <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item?.tongrut.toLocaleString("vi-VN")}</TableCell>
                                                                        {/* <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
																	<form
																		onSubmit={(e) => {
																			e.preventDefault();
																			const dataForm = {
																				id: item._id,
																				money: e.target.money.value,
																			};
																			axios
																				.post(
																					`${process.env.REACT_APP_API_URL}/auth/update`,
																					dataForm
																				)
																				.then((res) => {
																					setUser(res.data.data);
																					setLoad(false);
																					swal("Cộng/Trừ tiền thành công!", "", "success")
																				});
																		}}
																	>
																		<input
																			style={{ width: "100px" }}
																			id={item._id}
																			name="money"
																			type="number"
																		/>
																		<Button type="submit">Xác nhận</Button>
																	</form>
																</TableCell> */}
                                                                        {/* <TableCell sx={{ fontWeight: "600", padding: "10px" }}>
																	<form
																		onSubmit={(e) => {
																			e.preventDefault();
																			const dataForm = {
																				id: item._id,
																				money: e.target.money.value,
																			};
																			axios
																				.post(
																					`${process.env.REACT_APP_API_URL}/auth/adminthuong`,
																					dataForm
																				)
																				.then((res) => {
																					
																					setLoad(false);
																					swal("Thưởng thành công!", "", "success")
																				});
																		}}
																	>
																		<input
																			style={{ width: "100px" }}
																			id={item._id}
																			name="money"
																			type="number"
																		/>
																		<Button type="submit">Xác nhận</Button>
																	</form>
																</TableCell> */}
                                                                        {/* <TableCell
																	sx={{ fontWeight: "600", display: "flex" }}
																>
																	{item.isLock == false ? (
																		<Button
                                                                            className="btn-lock"
																			onClick={() => {
																				axios
																					.post(
																						`${process.env.REACT_APP_API_URL}/auth/lockkey`,
																						{
																							id: item._id,

																							isLock: true,
																						}
																					)
																					.then((res) => {
																						setLoad(false);
																						
																					});
																			}}
																		>
																			Khóa
																		</Button>
																	) : (
																		<Button
                                                                            className="btn-unlock"
																			onClick={() => {
																				axios
																					.post(
																						`${process.env.REACT_APP_API_URL}/auth/lockkey`,
																						{
																							id: item._id,

																							isLock: false,
																						}
																					)
																					.then((res) => {
																						setLoad(false);
																						
																					});
																			}}
																		>
																			Mở
																		</Button>
																	)}
																	<Button
																		onClick={() =>
																			navigate(`/admin/user/${item._id}`)
																		}
																	>
																		Xem
																	</Button>
																</TableCell> */}
                                                                    </TableRow>
                                                                </>
                                                            ))}
                                                        </TableBody>
                                                    ) : (
                                                        <div>Đang cập nhật</div>
                                                    )}
                                                </Table>
                                            </Box>
                                        </div>
                                        {searched.length < 1 && (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    pt: 3
                                                }}>
                                                {data != null ? <Pagination color="primary" count={Math.floor(data.length / 12) + 1} size="small" onChange={handleChange} /> : null}
                                            </Box>
                                        )}
                                    </>
                                ) : (
                                    <div>Hiện chưa có user</div>
                                )}
                            </Container>
                        </Box>
                    }
                </DashboardLayout>
            </ThemeProvider>
        </>
    );
}
export default UsersByEmployee;
