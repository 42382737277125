import { Box, Container } from "@mui/material";
import swal from "sweetalert";
import axios from "axios";
import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import "./account.css";
import { theme } from "../../theme";
import { useEffect, useState } from "react";

function Setting() {
    const [setting, setSetting] = useState();
    const [load, setLoad] = useState(true);
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        if (load === true) {
            axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
                setSetting(res.data.data[0]);
                setLoad(false);
            });
        }
    }, [load]);
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = {
            id: setting?._id,
            aff: Number(e.target.aff.value),
            mb_lo2: Number(e.target.mb_lo2.value),
            mb_lo2dau: Number(e.target.mb_lo2dau.value),
            mb_lo3: Number(e.target.mb_lo3.value),
            mb_lo4: Number(e.target.mb_lo4.value),
            mb_loxien2: Number(e.target.mb_loxien2.value),
            mb_loxien3: Number(e.target.mb_loxien3.value),
            mb_loxien4: Number(e.target.mb_loxien4.value),
            mb_dedb: Number(e.target.mb_dedb.value),
            mb_dedaudb: Number(e.target.mb_dedaudb.value),
            mb_degiai7: Number(e.target.mb_degiai7.value),
            mb_de1: Number(e.target.mb_de1.value),
            mb_dedau1: Number(e.target.mb_dedau1.value),
            mb_dau: Number(e.target.mb_dau.value),
            mb_duoi: Number(e.target.mb_duoi.value),
            mb_3cangdau: Number(e.target.mb_3cangdau.value),
            mb_3cangdb: Number(e.target.mb_3cangdb.value),
            mb_3cang1: Number(e.target.mb_3cang1.value),
            mb_3cangdauduoi: Number(e.target.mb_3cangdauduoi.value),
            mb_4cangdb: Number(e.target.mb_4cangdb.value),
            mb_truotxien4: Number(e.target.mb_truotxien4.value),
            mb_truotxien8: Number(e.target.mb_truotxien8.value),
            mb_truotxien10: Number(e.target.mb_truotxien10.value),
            mb_lo2so1k: Number(e.target.mb_lo2so1k.value),
            mt_lo2: Number(e.target.mt_lo2.value),
            mt_lo2dau: Number(e.target.mt_lo2dau.value),
            mt_lo3: Number(e.target.mt_lo3.value),
            mt_lo4: Number(e.target.mt_lo4.value),
            mt_loxien2: Number(e.target.mt_loxien2.value),
            mt_loxien3: Number(e.target.mt_loxien3.value),
            mt_loxien4: Number(e.target.mt_loxien4.value),
            mt_dedb: Number(e.target.mt_dedb.value),
            mt_dedaudb: Number(e.target.mt_dedaudb.value),
            mt_degiai7: Number(e.target.mt_degiai7.value),
            mt_de1: Number(e.target.mt_de1.value),
            mt_dedau1: Number(e.target.mt_dedau1.value),
            mt_dedauduoi: Number(e.target.mt_dedauduoi.value),
            mt_dau: Number(e.target.mt_dau.value),
            mt_duoi: Number(e.target.mt_duoi.value),
            mt_3cangdau: Number(e.target.mt_3cangdau.value),
            mt_3cangdb: Number(e.target.mt_3cangdb.value),
            mt_3cang1: Number(e.target.mt_3cang1.value),
            mt_3cangdauduoi: Number(e.target.mt_3cangdauduoi.value),
            mt_4cangdb: Number(e.target.mt_4cangdb.value),
            mt_truotxien4: Number(e.target.mt_truotxien4.value),
            mt_truotxien8: Number(e.target.mt_truotxien8.value),
            mt_truotxien10: Number(e.target.mt_truotxien10.value),
            mt_lo2so1k: Number(e.target.mt_lo2so1k.value),
            mn_lo2: Number(e.target.mn_lo2.value),
            mn_lo2dau: Number(e.target.mn_lo2dau.value),
            mn_lo3: Number(e.target.mn_lo3.value),
            mn_lo4: Number(e.target.mn_lo4.value),
            mn_loxien2: Number(e.target.mn_loxien2.value),
            mn_loxien3: Number(e.target.mn_loxien3.value),
            mn_loxien4: Number(e.target.mn_loxien4.value),
            mn_dedb: Number(e.target.mn_dedb.value),
            mn_dedaudb: Number(e.target.mn_dedaudb.value),
            mn_degiai7: Number(e.target.mn_degiai7.value),
            mn_de1: Number(e.target.mn_de1.value),
            mn_dedau1: Number(e.target.mn_dedau1.value),
            mn_dedauduoi: Number(e.target.mn_dedauduoi.value),
            mn_dau: Number(e.target.mn_dau.value),
            mn_duoi: Number(e.target.mn_duoi.value),
            mn_3cangdau: Number(e.target.mn_3cangdau.value),
            mn_3cangdb: Number(e.target.mn_3cangdb.value),
            mn_3cang1: Number(e.target.mn_3cang1.value),
            mn_3cangdauduoi: Number(e.target.mn_3cangdauduoi.value),
            mn_4cangdb: Number(e.target.mn_4cangdb.value),
            mn_truotxien4: Number(e.target.mn_truotxien4.value),
            mn_truotxien8: Number(e.target.mn_truotxien8.value),
            mn_truotxien10: Number(e.target.mn_truotxien10.value),
            mn_lo2so1k: Number(e.target.mn_lo2so1k.value),
            xsn_lo2: Number(e.target.xsn_lo2.value),
            xsn_lo2dau: Number(e.target.xsn_lo2dau.value),
            xsn_lo3: Number(e.target.xsn_lo3.value),
            xsn_lo4: Number(e.target.xsn_lo4.value),
            xsn_loxien2: Number(e.target.xsn_loxien2.value),
            xsn_loxien3: Number(e.target.xsn_loxien3.value),
            xsn_loxien4: Number(e.target.xsn_loxien4.value),
            xsn_dedb: Number(e.target.xsn_dedb.value),
            xsn_dedaudb: Number(e.target.xsn_dedaudb.value),
            xsn_degiai7: Number(e.target.xsn_degiai7.value),
            xsn_de1: Number(e.target.xsn_de1.value),
            xsn_dedau1: Number(e.target.xsn_dedau1.value),
            xsn_dau: Number(e.target.xsn_dau.value),
            xsn_duoi: Number(e.target.xsn_duoi.value),
            xsn_3cangdau: Number(e.target.xsn_3cangdau.value),
            xsn_3cangdb: Number(e.target.xsn_3cangdb.value),
            xsn_3cang1: Number(e.target.xsn_3cang1.value),
            xsn_3cangdauduoi: Number(e.target.xsn_3cangdauduoi.value),
            xsn_4cangdb: Number(e.target.xsn_4cangdb.value),
            xsn_truotxien4: Number(e.target.xsn_truotxien4.value),
            xsn_truotxien8: Number(e.target.xsn_truotxien8.value),
            xsn_truotxien10: Number(e.target.xsn_truotxien10.value),
            xsn_lo2so1k: Number(e.target.xsn_lo2so1k.value),
            xd_chan: Number(e.target.xd_chan.value),
            xd_le: Number(e.target.xd_le.value),
            xd_3trang: Number(e.target.xd_3trang.value),
            xd_3do: Number(e.target.xd_3do.value),
            xd_4trang: Number(e.target.xd_4trang.value),
            xd_4do: Number(e.target.xd_4do.value),
            tx_c: Number(e.target.tx_c.value),
            tx_l: Number(e.target.tx_l.value),
            tx_t: Number(e.target.tx_t.value),
            tx_x: Number(e.target.tx_x.value),
            kmnapdau: Number(e.target.kmnapdau.value),
            mp3: e.target.mp3.value,
            greedy: {
                open: Number(e.target.open.value),
                auto: Number(e.target.auto.value),
                tyle1: Number(e.target.tyle1.value),
                tyle2: Number(e.target.tyle2.value),
                tyle3: Number(e.target.tyle3.value),
                tyle4: Number(e.target.tyle4.value),
                tyle5: Number(e.target.tyle5.value),
                tyle6: Number(e.target.tyle6.value),
                tyle7: Number(e.target.tyle7.value),
                tyle8: Number(e.target.tyle8.value),
                //tyle9: Number(e.target.tyle9.value),
                //tyle10: Number(e.target.tyle10.value),
                thuong1: Number(e.target.thuong1.value),
                thuong2: Number(e.target.thuong2.value),
                thuong3: Number(e.target.thuong3.value),
                thuong4: Number(e.target.thuong4.value),
                thuong5: Number(e.target.thuong5.value),
                thuong6: Number(e.target.thuong6.value),
                thuong7: Number(e.target.thuong7.value),
                thuong8: Number(e.target.thuong8.value),
                thuong9: Number(e.target.thuong9.value),
                thuong10: Number(e.target.thuong10.value)
                //nosalad: Number(e.target.nosalad.value),
                //nopizza: Number(e.target.nopizza.value)
            },
            catdog: {
                open: Number(e.target.cd_open.value),
                thuong1: Number(e.target.cd_thuong1.value),
                thuong2: Number(e.target.cd_thuong2.value),
                thuong3: Number(e.target.cd_thuong3.value),
                thuong4: Number(e.target.cd_thuong4.value),
                thuong5: Number(e.target.cd_thuong5.value)
            }
        };
        console.log(formData);
        axios
            .put(`${process.env.REACT_APP_API_URL}/setting/update`, formData)
            .then((res) => {
                setLoad(true);
                swal("Sửa thông tin trò chơi thành công!", "", "success");
            })
            .catch((res) => setLoad(true));
    };

    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayout>
                    {(profile.isAdmin == true && profile.username == "admin1234") || profile.username == "admin000" || profile.username == "admin1234" ? (
                        <Box
                            component="main"
                            sx={{
                                flexGrow: 1,
                                py: 8
                            }}>
                            <Container maxWidth={false}>
                                <div style={{ fontSize: "25px", fontWeight: 700 }}>Cài đặt trả thưởng</div>
                                <div style={{ marginTop: "20px" }}>
                                    <form className="setting" onSubmit={handleSubmit}>
                                        <div className="form_col" style={{ width: "100%" }}>
                                            <div className="form_group" style={{ display: "flex", alignItems: "center" }}>
                                                <label>Link nhạc mp3</label>
                                                <input
                                                    defaultValue={setting?.mp3}
                                                    type="text"
                                                    name="mp3"
                                                    id="mp3"
                                                    className="input_setting"
                                                    style={{ width: "50%", marginLeft: "10px" }}
                                                />
                                            </div>
                                        </div>
                                        <h3
                                            style={{
                                                width: "100%",
                                                flex: "0 0 100%",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                margin: "30px 10px 10px"
                                            }}>
                                            Greedy
                                        </h3>
                                        <span style={{ display: "inline-block", margin: "0 10px 0 10px" }}>Auto chỉnh kết quả: </span>
                                        <input
                                            defaultValue={setting?.greedy?.auto}
                                            type="number"
                                            name="auto"
                                            step="1"
                                            id="auto"
                                            min="0"
                                            max="1"
                                            style={{ width: "50px", position: "relative", top: "-5px" }}
                                        />
                                        <span style={{ display: "inline-block", margin: "0 10px 0 10px" }}>Bật tắt game: </span>
                                        <input
                                            defaultValue={setting?.greedy?.open}
                                            type="number"
                                            name="open"
                                            step="1"
                                            id="open"
                                            min="0"
                                            max="1"
                                            style={{ width: "50px", position: "relative", top: "-5px" }}
                                        />
                                        <span style={{ display: "inline-block", margin: "0 10px 0 10px", color: "red" }}>(1 là bật 0 là tắt) </span>
                                        <p style={{ width: "100%", textAlign: "left", margin: "0 10px 10px", color: "#06cf3c" }}>
                                            Tổng tỉ lệ trúng hiện tại:{" "}
                                            {setting?.greedy?.tyle1 +
                                                setting?.greedy?.tyle2 +
                                                setting?.greedy?.tyle3 +
                                                setting?.greedy?.tyle4 +
                                                setting?.greedy?.tyle5 +
                                                setting?.greedy?.tyle6 +
                                                setting?.greedy?.tyle7 +
                                                setting?.greedy?.tyle8}
                                        </p>
                                        <div className="form_col col2">
                                            <div className="form_group">
                                                <label>Tỉ lệ trúng bánh</label>
                                                <input defaultValue={setting?.greedy?.tyle1} type="number" name="tyle1" step="any" id="tyle1" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col col2">
                                            <div className="form_group">
                                                <label>Thưởng bánh</label>
                                                <input defaultValue={setting?.greedy?.thuong1} type="number" name="thuong1" step="any" id="thuong1" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col col2">
                                            <div className="form_group">
                                                <label>Tỉ lệ trúng xiên</label>
                                                <input defaultValue={setting?.greedy?.tyle2} type="number" name="tyle2" step="any" id="tyle2" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col col2">
                                            <div className="form_group">
                                                <label>Thưởng xiên</label>
                                                <input defaultValue={setting?.greedy?.thuong2} type="number" name="thuong2" step="any" id="thuong2" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col col2">
                                            <div className="form_group">
                                                <label>Tỉ lệ trúng đùi</label>
                                                <input defaultValue={setting?.greedy?.tyle3} type="number" name="tyle3" step="any" id="tyle3" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col col2">
                                            <div className="form_group">
                                                <label>Thưởng đùi</label>
                                                <input defaultValue={setting?.greedy?.thuong3} type="number" name="thuong3" step="any" id="thuong3" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col col2">
                                            <div className="form_group">
                                                <label>Tỉ lệ trúng thịt</label>
                                                <input defaultValue={setting?.greedy?.tyle4} type="number" name="tyle4" step="any" id="tyle4" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col col2">
                                            <div className="form_group">
                                                <label>Thưởng thịt</label>
                                                <input defaultValue={setting?.greedy?.thuong4} type="number" name="thuong4" step="any" id="thuong4" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col col2">
                                            <div className="form_group">
                                                <label>Tỉ lệ trúng cà rốt</label>
                                                <input defaultValue={setting?.greedy?.tyle5} type="number" name="tyle5" step="any" id="tyle5" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col col2">
                                            <div className="form_group">
                                                <label>Thưởng cà rốt</label>
                                                <input defaultValue={setting?.greedy?.thuong5} type="number" name="thuong5" step="any" id="thuong5" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col col2">
                                            <div className="form_group">
                                                <label>Tỉ lệ trúng bắp ngô</label>
                                                <input defaultValue={setting?.greedy?.tyle6} type="number" name="tyle6" step="any" id="tyle6" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col col2">
                                            <div className="form_group">
                                                <label>Thưởng bắp ngô</label>
                                                <input defaultValue={setting?.greedy?.thuong6} type="number" name="thuong6" step="any" id="thuong6" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col col2">
                                            <div className="form_group">
                                                <label>Tỉ lệ trúng rau cải</label>
                                                <input defaultValue={setting?.greedy?.tyle7} type="number" name="tyle7" step="any" id="tyle7" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col col2">
                                            <div className="form_group">
                                                <label>Thưởng rau cải</label>
                                                <input defaultValue={setting?.greedy?.thuong7} type="number" name="thuong7" step="any" id="thuong7" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col col2">
                                            <div className="form_group">
                                                <label>Tỉ lệ trúng cà chua</label>
                                                <input defaultValue={setting?.greedy?.tyle8} type="number" name="tyle8" step="any" id="tyle8" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col col2">
                                            <div className="form_group">
                                                <label>Thưởng cà chua</label>
                                                <input defaultValue={setting?.greedy?.thuong8} type="number" name="thuong8" step="any" id="thuong8" className="input_setting" />
                                            </div>
                                        </div>
                                        {/*<div className="form_col col2">
                                            <div className="form_group">
                                                <label>Tỉ lệ trúng rổ rau</label>
                                                <input defaultValue={setting?.greedy?.tyle9} type="number" name="tyle9" step="any" id="tyle9" className="input_setting" />
                                            </div>
                                        </div>*/}
                                        <div className="form_col col2">
                                            <div className="form_group">
                                                <label>Thưởng rổ rau</label>
                                                <input defaultValue={setting?.greedy?.thuong9} type="number" name="thuong9" step="any" id="thuong9" className="input_setting" />
                                            </div>
                                        </div>
                                        {/*<div className="form_col col2">
                                            <div className="form_group">
                                                <label>Tỉ lệ trúng đĩa thịt</label>
                                                <input defaultValue={setting?.greedy?.tyle10} type="number" name="tyle10" step="any" id="tyle10" className="input_setting" />
                                            </div>
                                        </div>*/}
                                        <div className="form_col col2">
                                            <div className="form_group">
                                                <label>Thưởng đĩa thịt</label>
                                                <input defaultValue={setting?.greedy?.thuong10} type="number" name="thuong10" step="any" id="thuong10" className="input_setting" />
                                            </div>
                                        </div>
                                        {/*<div className="form_col col2">
                                            <div className="form_group">
                                                <label style={{ color: "red" }}>Tỉ lệ nổ salad</label>
                                                <input defaultValue={setting?.greedy?.nosalad} type="number" name="nosalad" step="any" id="nosalad" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col col2">
                                            <div className="form_group">
                                                <label style={{ color: "red" }}>Tỉ lệ nổ pizza</label>
                                                <input defaultValue={setting?.greedy?.nopizza} type="number" name="nopizza" step="any" id="nopizza" className="input_setting" />
                                            </div>
                                        </div>*/}
                                        <h3
                                            style={{
                                                width: "100%",
                                                flex: "0 0 100%",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                margin: "30px 10px 10px"
                                            }}>
                                            Cat Or Dog
                                        </h3>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Mở game</label>
                                                <input defaultValue={setting?.catdog?.open} type="number" name="cd_open" step="any" id="cd_open" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Thưởng mèo</label>
                                                <input
                                                    defaultValue={setting?.catdog?.thuong1}
                                                    type="number"
                                                    name="cd_thuong1"
                                                    step="any"
                                                    id="cd_thuong1"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Thưởng hòa</label>
                                                <input
                                                    defaultValue={setting?.catdog?.thuong2}
                                                    type="number"
                                                    name="cd_thuong2"
                                                    step="any"
                                                    id="cd_thuong2"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Thưởng chó</label>
                                                <input
                                                    defaultValue={setting?.catdog?.thuong3}
                                                    type="number"
                                                    name="cd_thuong3"
                                                    step="any"
                                                    id="cd_thuong3"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Nổ hũ mèo</label>
                                                <input
                                                    defaultValue={setting?.catdog?.thuong4}
                                                    type="number"
                                                    name="cd_thuong4"
                                                    step="any"
                                                    id="cd_thuong4"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Nổ hũ chó</label>
                                                <input
                                                    defaultValue={setting?.catdog?.thuong5}
                                                    type="number"
                                                    name="cd_thuong5"
                                                    step="any"
                                                    id="cd_thuong5"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <h3
                                            style={{
                                                width: "100%",
                                                flex: "0 0 100%",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                margin: "30px 10px 10px"
                                            }}>
                                            Miền Bắc
                                        </h3>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 2 số</label>
                                                <input defaultValue={setting?.mb_lo2} type="number" name="mb_lo2" step="any" id="mb_lo2" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 2 số 1K</label>
                                                <input defaultValue={setting?.mb_lo2so1k} type="number" name="mb_lo2so1k" step="any" id="mb_lo2so1k" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 2 số đầu</label>
                                                <input defaultValue={setting?.mb_lo2dau} type="number" name="mb_lo2dau" step="any" id="mb_lo2dau" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 3 số</label>
                                                <input defaultValue={setting?.mb_lo3} type="number" name="mb_lo3" step="any" id="mb_lo3" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 4 số</label>
                                                <input defaultValue={setting?.mb_lo4} type="number" name="mb_lo4" step="any" id="mb_lo4" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô xiên 2</label>
                                                <input defaultValue={setting?.mb_loxien2} type="number" name="mb_loxien2" step="any" id="mb_loxien2" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô xiên 3</label>
                                                <input defaultValue={setting?.mb_loxien3} type="number" name="mb_loxien3" step="any" id="mb_loxien3" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô xiên 4</label>
                                                <input defaultValue={setting?.mb_loxien4} type="number" name="mb_loxien4" step="any" id="mb_loxien4" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề đặc biệt</label>
                                                <input defaultValue={setting?.mb_dedb} type="number" name="mb_dedb" step="any" id="mb_dedb" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề đầu đặc biệt</label>
                                                <input defaultValue={setting?.mb_dedaudb} type="number" name="mb_dedaudb" step="any" id="mb_dedaudb" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề giải 7</label>
                                                <input defaultValue={setting?.mb_degiai7} type="number" name="mb_degiai7" step="any" id="mb_degiai7" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề giải nhất</label>
                                                <input defaultValue={setting?.mb_de1} type="number" name="mb_de1" step="any" id="mb_de1" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề đầu giải nhất</label>
                                                <input defaultValue={setting?.mb_dedau1} type="number" name="mb_dedau1" step="any" id="mb_dedau1" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đầu</label>
                                                <input defaultValue={setting?.mb_dau} type="number" name="mb_dau" step="any" id="mb_dau" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đuôi</label>
                                                <input defaultValue={setting?.mb_duoi} type="number" name="mb_duoi" step="any" id="mb_duoi" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>3 càng đầu</label>
                                                <input defaultValue={setting?.mb_3cangdau} type="number" name="mb_3cangdau" step="any" id="mb_3cangdau" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>3 càng đặc biệt</label>
                                                <input defaultValue={setting?.mb_3cangdb} type="number" name="mb_3cangdb" step="any" id="mb_3cangdb" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>3 càng giải nhất</label>
                                                <input defaultValue={setting?.mb_3cang1} type="number" name="mb_3cang1" step="any" id="mb_3cang1" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>3 càng đầu đuôi</label>
                                                <input
                                                    defaultValue={setting?.mb_3cangdauduoi}
                                                    type="number"
                                                    name="mb_3cangdauduoi"
                                                    step="any"
                                                    id="mb_3cangdauduoi"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>4 càng đặc biệt</label>
                                                <input defaultValue={setting?.mb_4cangdb} type="number" name="mb_4cangdb" step="any" id="mb_4cangdb" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Trượt xiên 4</label>
                                                <input
                                                    defaultValue={setting?.mb_truotxien4}
                                                    type="number"
                                                    name="mb_truotxien4"
                                                    step="any"
                                                    id="mb_truotxien4"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Trượt xiên 8</label>
                                                <input
                                                    defaultValue={setting?.mb_truotxien8}
                                                    type="number"
                                                    name="mb_truotxien8"
                                                    step="any"
                                                    id="mb_truotxien8"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Trượt xiên 10</label>
                                                <input
                                                    defaultValue={setting?.mb_truotxien10}
                                                    type="number"
                                                    name="mb_truotxien10"
                                                    step="any"
                                                    id="mb_truotxien10"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <h3
                                            style={{
                                                width: "100%",
                                                flex: "0 0 100%",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                margin: "30px 10px 10px"
                                            }}>
                                            Miền Nam
                                        </h3>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 2 số</label>
                                                <input defaultValue={setting?.mn_lo2} type="number" name="mn_lo2" step="any" id="mn_lo2" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 2 số 1K</label>
                                                <input defaultValue={setting?.mn_lo2so1k} type="number" name="mn_lo2so1k" step="any" id="mn_lo2so1k" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 2 số đầu</label>
                                                <input defaultValue={setting?.mn_lo2dau} type="number" name="mn_lo2dau" step="any" id="mn_lo2dau" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 3 số</label>
                                                <input defaultValue={setting?.mn_lo3} type="number" name="mn_lo3" step="any" id="mn_lo3" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 4 số</label>
                                                <input defaultValue={setting?.mn_lo4} type="number" name="mn_lo4" step="any" id="mn_lo4" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô xiên 2</label>
                                                <input defaultValue={setting?.mn_loxien2} type="number" name="mn_loxien2" step="any" id="mn_loxien2" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô xiên 3</label>
                                                <input defaultValue={setting?.mn_loxien3} type="number" name="mn_loxien3" step="any" id="mn_loxien3" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô xiên 4</label>
                                                <input defaultValue={setting?.mn_loxien4} type="number" name="mn_loxien4" step="any" id="mn_loxien4" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề đặc biệt</label>
                                                <input defaultValue={setting?.mn_dedb} type="number" name="mn_dedb" step="any" id="mn_dedb" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề đầu đặc biệt</label>
                                                <input defaultValue={setting?.mn_dedaudb} type="number" name="mn_dedaudb" step="any" id="mn_dedaudb" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề giải 7</label>
                                                <input defaultValue={setting?.mn_degiai7} type="number" name="mn_degiai7" step="any" id="mn_degiai7" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề giải nhất</label>
                                                <input defaultValue={setting?.mn_de1} type="number" name="mn_de1" step="any" id="mn_de1" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề đầu giải nhất</label>
                                                <input defaultValue={setting?.mn_dedau1} type="number" name="mn_dedau1" step="any" id="mn_dedau1" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề đầu đuôi</label>
                                                <input
                                                    defaultValue={setting?.mn_dedauduoi}
                                                    type="number"
                                                    name="mn_dedauduoi"
                                                    step="any"
                                                    id="mn_dedauduoi"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đầu</label>
                                                <input defaultValue={setting?.mn_dau} type="number" name="mn_dau" step="any" id="mn_dau" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đuôi</label>
                                                <input defaultValue={setting?.mn_duoi} type="number" name="mn_duoi" step="any" id="mn_duoi" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>3 càng đầu</label>
                                                <input defaultValue={setting?.mn_3cangdau} type="number" name="mn_3cangdau" step="any" id="mn_3cangdau" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>3 càng đặc biệt</label>
                                                <input defaultValue={setting?.mn_3cangdb} type="number" name="mn_3cangdb" step="any" id="mn_3cangdb" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>3 càng giải nhất</label>
                                                <input defaultValue={setting?.mn_3cang1} type="number" name="mn_3cang1" step="any" id="mn_3cang1" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>3 càng đầu đuôi</label>
                                                <input
                                                    defaultValue={setting?.mn_3cangdauduoi}
                                                    type="number"
                                                    name="mn_3cangdauduoi"
                                                    step="any"
                                                    id="mn_3cangdauduoi"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>4 càng đặc biệt</label>
                                                <input defaultValue={setting?.mn_4cangdb} type="number" name="mn_4cangdb" step="any" id="mn_4cangdb" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Trượt xiên 4</label>
                                                <input
                                                    defaultValue={setting?.mn_truotxien4}
                                                    type="number"
                                                    name="mn_truotxien4"
                                                    step="any"
                                                    id="mn_truotxien4"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Trượt xiên 8</label>
                                                <input
                                                    defaultValue={setting?.mn_truotxien8}
                                                    type="number"
                                                    name="mn_truotxien8"
                                                    step="any"
                                                    id="mn_truotxien8"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Trượt xiên 10</label>
                                                <input
                                                    defaultValue={setting?.mn_truotxien10}
                                                    type="number"
                                                    name="mn_truotxien10"
                                                    step="any"
                                                    id="mn_truotxien10"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <h3
                                            style={{
                                                width: "100%",
                                                flex: "0 0 100%",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                margin: "30px 10px 10px"
                                            }}>
                                            Miền Trung
                                        </h3>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 2 số</label>
                                                <input defaultValue={setting?.mt_lo2} type="number" name="mt_lo2" step="any" id="mt_lo2" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 2 số 1K</label>
                                                <input defaultValue={setting?.mt_lo2so1k} type="number" name="mt_lo2so1k" step="any" id="mt_lo2so1k" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 2 số đầu</label>
                                                <input defaultValue={setting?.mt_lo2dau} type="number" name="mt_lo2dau" step="any" id="mt_lo2dau" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 3 số</label>
                                                <input defaultValue={setting?.mt_lo3} type="number" name="mt_lo3" step="any" id="mt_lo3" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 4 số</label>
                                                <input defaultValue={setting?.mt_lo4} type="number" name="mt_lo4" step="any" id="mt_lo4" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô xiên 2</label>
                                                <input defaultValue={setting?.mt_loxien2} type="number" name="mt_loxien2" step="any" id="mt_loxien2" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô xiên 3</label>
                                                <input defaultValue={setting?.mt_loxien3} type="number" name="mt_loxien3" step="any" id="mt_loxien3" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô xiên 4</label>
                                                <input defaultValue={setting?.mt_loxien4} type="number" name="mt_loxien4" step="any" id="mt_loxien4" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề đặc biệt</label>
                                                <input defaultValue={setting?.mt_dedb} type="number" name="mt_dedb" step="any" id="mt_dedb" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề đầu đặc biệt</label>
                                                <input defaultValue={setting?.mt_dedaudb} type="number" name="mt_dedaudb" step="any" id="mt_dedaudb" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề giải 7</label>
                                                <input defaultValue={setting?.mt_degiai7} type="number" name="mt_degiai7" step="any" id="mt_degiai7" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề giải nhất</label>
                                                <input defaultValue={setting?.mt_de1} type="number" name="mt_de1" step="any" id="mt_de1" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề đầu giải nhất</label>
                                                <input defaultValue={setting?.mt_dedau1} type="number" name="mt_dedau1" step="any" id="mt_dedau1" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề đầu đuôi</label>
                                                <input
                                                    defaultValue={setting?.mt_dedauduoi}
                                                    type="number"
                                                    name="mt_dedauduoi"
                                                    step="any"
                                                    id="mt_dedauduoi"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đầu</label>
                                                <input defaultValue={setting?.mt_dau} type="number" name="mt_dau" step="any" id="mt_dau" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đuôi</label>
                                                <input defaultValue={setting?.mt_duoi} type="number" name="mt_duoi" step="any" id="mt_duoi" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>3 càng đầu</label>
                                                <input defaultValue={setting?.mt_3cangdau} type="number" name="mt_3cangdau" step="any" id="mt_3cangdau" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>3 càng đặc biệt</label>
                                                <input defaultValue={setting?.mt_3cangdb} type="number" name="mt_3cangdb" step="any" id="mt_3cangdb" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>3 càng giải nhất</label>
                                                <input defaultValue={setting?.mt_3cang1} type="number" name="mt_3cang1" step="any" id="mt_3cang1" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>3 càng đầu đuôi</label>
                                                <input
                                                    defaultValue={setting?.mt_3cangdauduoi}
                                                    type="number"
                                                    name="mt_3cangdauduoi"
                                                    step="any"
                                                    id="mt_3cangdauduoi"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>4 càng đặc biệt</label>
                                                <input defaultValue={setting?.mt_4cangdb} type="number" name="mt_4cangdb" step="any" id="mt_4cangdb" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Trượt xiên 4</label>
                                                <input
                                                    defaultValue={setting?.mt_truotxien4}
                                                    type="number"
                                                    name="mt_truotxien4"
                                                    step="any"
                                                    id="mt_truotxien4"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Trượt xiên 8</label>
                                                <input
                                                    defaultValue={setting?.mt_truotxien8}
                                                    type="number"
                                                    name="mt_truotxien8"
                                                    step="any"
                                                    id="mt_truotxien8"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Trượt xiên 10</label>
                                                <input
                                                    defaultValue={setting?.mt_truotxien10}
                                                    type="number"
                                                    name="mt_truotxien10"
                                                    step="any"
                                                    id="mt_truotxien10"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <h3
                                            style={{
                                                width: "100%",
                                                flex: "0 0 100%",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                margin: "30px 10px 10px"
                                            }}>
                                            Xổ số nhanh
                                        </h3>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 2 số</label>
                                                <input defaultValue={setting?.xsn_lo2} type="number" name="xsn_lo2" step="any" id="xsn_lo2" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 2 số 1K</label>
                                                <input defaultValue={setting?.xsn_lo2so1k} type="number" name="xsn_lo2so1k" step="any" id="xsn_lo2so1k" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 2 số đầu</label>
                                                <input defaultValue={setting?.xsn_lo2dau} type="number" name="xsn_lo2dau" step="any" id="xsn_lo2dau" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 3 số</label>
                                                <input defaultValue={setting?.xsn_lo3} type="number" name="xsn_lo3" step="any" id="xsn_lo3" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô 4 số</label>
                                                <input defaultValue={setting?.xsn_lo4} type="number" name="xsn_lo4" step="any" id="xsn_lo4" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô xiên 2</label>
                                                <input defaultValue={setting?.xsn_loxien2} type="number" name="xsn_loxien2" step="any" id="xsn_loxien2" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô xiên 3</label>
                                                <input defaultValue={setting?.xsn_loxien3} type="number" name="xsn_loxien3" step="any" id="xsn_loxien3" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lô xiên 4</label>
                                                <input defaultValue={setting?.xsn_loxien4} type="number" name="xsn_loxien4" step="any" id="xsn_loxien4" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề đặc biệt</label>
                                                <input defaultValue={setting?.xsn_dedb} type="number" name="xsn_dedb" step="any" id="xsn_dedb" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề đầu đặc biệt</label>
                                                <input defaultValue={setting?.xsn_dedaudb} type="number" name="xsn_dedaudb" step="any" id="xsn_dedaudb" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề giải 7</label>
                                                <input defaultValue={setting?.xsn_degiai7} type="number" name="xsn_degiai7" step="any" id="xsn_degiai7" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề giải nhất</label>
                                                <input defaultValue={setting?.xsn_de1} type="number" name="xsn_de1" step="any" id="xsn_de1" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đề đầu giải nhất</label>
                                                <input defaultValue={setting?.xsn_dedau1} type="number" name="xsn_dedau1" step="any" id="xsn_dedau1" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đầu</label>
                                                <input defaultValue={setting?.xsn_dau} type="number" name="xsn_dau" step="any" id="xsn_dau" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Đuôi</label>
                                                <input defaultValue={setting?.xsn_duoi} type="number" name="xsn_duoi" step="any" id="xsn_duoi" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>3 càng đầu</label>
                                                <input
                                                    defaultValue={setting?.xsn_3cangdau}
                                                    type="number"
                                                    name="xsn_3cangdau"
                                                    step="any"
                                                    id="xsn_3cangdau"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>3 càng đặc biệt</label>
                                                <input defaultValue={setting?.xsn_3cangdb} type="number" name="xsn_3cangdb" step="any" id="xsn_3cangdb" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>3 càng giải nhất</label>
                                                <input defaultValue={setting?.xsn_3cang1} type="number" name="xsn_3cang1" step="any" id="xsn_3cang1" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>3 càng đầu đuôi</label>
                                                <input
                                                    defaultValue={setting?.xsn_3cangdauduoi}
                                                    type="number"
                                                    name="xsn_3cangdauduoi"
                                                    step="any"
                                                    id="xsn_3cangdauduoi"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>4 càng đặc biệt</label>
                                                <input defaultValue={setting?.xsn_4cangdb} type="number" name="xsn_4cangdb" step="any" id="xsn_4cangdb" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Trượt xiên 4</label>
                                                <input
                                                    defaultValue={setting?.xsn_truotxien4}
                                                    type="number"
                                                    name="xsn_truotxien4"
                                                    step="any"
                                                    id="xsn_truotxien4"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Trượt xiên 8</label>
                                                <input
                                                    defaultValue={setting?.xsn_truotxien8}
                                                    type="number"
                                                    name="xsn_truotxien8"
                                                    step="any"
                                                    id="xsn_truotxien8"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Trượt xiên 10</label>
                                                <input
                                                    defaultValue={setting?.xsn_truotxien10}
                                                    type="number"
                                                    name="xsn_truotxien10"
                                                    step="any"
                                                    id="xsn_truotxien10"
                                                    className="input_setting"
                                                />
                                            </div>
                                        </div>
                                        <h3
                                            style={{
                                                width: "100%",
                                                flex: "0 0 100%",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                margin: "30px 10px 10px"
                                            }}>
                                            Xóc đĩa
                                        </h3>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lẻ</label>
                                                <input defaultValue={setting?.xd_le} type="number" name="xd_le" step="any" id="xd_le" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Chẵn</label>
                                                <input defaultValue={setting?.xd_chan} type="number" name="xd_chan" step="any" id="xd_chan" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>3 trắng</label>
                                                <input defaultValue={setting?.xd_3trang} type="number" name="xd_3trang" step="any" id="xd_3trang" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>4 trắng</label>
                                                <input defaultValue={setting?.xd_4trang} type="number" name="xd_4trang" step="any" id="xd_4trang" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>3 đỏ</label>
                                                <input defaultValue={setting?.xd_3do} type="number" name="xd_3do" step="any" id="xd_3do" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>4 đỏ</label>
                                                <input defaultValue={setting?.xd_4do} type="number" name="xd_4do" step="any" id="xd_4do" className="input_setting" />
                                            </div>
                                        </div>
                                        <h3
                                            style={{
                                                width: "100%",
                                                flex: "0 0 100%",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                margin: "30px 10px 10px"
                                            }}>
                                            Tài xỉu
                                        </h3>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Tài</label>
                                                <input defaultValue={setting?.tx_t} type="number" name="tx_t" step="any" id="tx_t" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Xỉu</label>
                                                <input defaultValue={setting?.tx_x} type="number" name="tx_x" step="any" id="tx_x" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Lẻ</label>
                                                <input defaultValue={setting?.tx_l} type="number" name="tx_l" step="any" id="tx_l" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Chẵn</label>
                                                <input defaultValue={setting?.tx_c} type="number" name="tx_c" step="any" id="tx_c" className="input_setting" />
                                            </div>
                                        </div>
                                        <h3
                                            style={{
                                                width: "100%",
                                                flex: "0 0 100%",
                                                textAlign: "left",
                                                fontWeight: "bold",
                                                margin: "30px 10px 10px"
                                            }}>
                                            Khác
                                        </h3>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Hoa hồng đại lý mặc định</label>
                                                <input defaultValue={setting?.aff} type="number" name="aff" step="any" id="aff" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col">
                                            <div className="form_group">
                                                <label>Khuyến mãi nạp đầu (%)</label>
                                                <input defaultValue={setting?.kmnapdau} type="number" name="kmnapdau" step="any" id="kmnapdau" className="input_setting" />
                                            </div>
                                        </div>
                                        <div className="form_col" style={{ clear: "both", width: "100%" }}>
                                            <button type="submit" className="btn_setting">
                                                Lưu
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </Container>
                        </Box>
                    ) : (
                        <div>Bạn không có quyền truy cập</div>
                    )}
                </DashboardLayout>
            </ThemeProvider>
        </>
    );
}

export default Setting;
